import classes from "./StockCalculationCard.module.scss";
import { CalculationGeneral } from "../../Models/CalculationGeneral";
import { useNavigate } from "react-router";
import { CALCULATION_DETAILS_ROUTE } from "../../Constants/ROUTE_PATH_TITLES";
import moment from "moment";

interface IStockCalculationCard {
  stockCalculation: CalculationGeneral;
}

export const StockCalculationCard: React.FC<IStockCalculationCard> = ({ stockCalculation }) => {
  const navigate = useNavigate();
  return (
    <div className={classes.calculationCardGeneral}>
      <div className={classes.calculationCardId}>{stockCalculation._stockCalculationId}</div>
      <div className={classes.calculationCardNameWrapper}>
        <div className={classes.calculationCardItem}>
          <div className={classes.calculationCardNameHeader}>İsim</div>
          <div className={classes.calculationCardName}>{stockCalculation.name}</div>
        </div>
        <div className={classes.calculationCardItem}>
          <div className={classes.calculationCardNameHeader}>Fatura No</div>
          <div className={classes.calculationCardName}>{stockCalculation.billNo}</div>
        </div>
        <div className={classes.calculationCardItem}>
          <div className={classes.calculationCardNameHeader}>Tarih</div>
          <div className={classes.calculationCardName}>{moment(stockCalculation.date).format("DD/MM/YYYY")}</div>
        </div>
        <div className={classes.calculationCardItem}>
          <div className={classes.calculationCardNameHeader}>Not</div>
          <div className={classes.calculationCardName}>{stockCalculation.note}</div>
        </div>
      </div>
      <div>
        <button
          style={{ marginRight: "1rem" }}
          onClick={() => navigate(`${CALCULATION_DETAILS_ROUTE.PATH}/${stockCalculation._stockCalculationId}`)}
          className={classes.cssbuttons_io_button}
        >
          Hesaplamaya Git
          <div className={classes.icon}>
            <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" fill="currentColor"></path>
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
};
